import {
  ScBox,
  ScColumnsResponsive,
  ScFlexItem,
  ScHeader3,
  ScImage,
} from '../components/styled-components';

import Image from '../components/image';
import React from 'react';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScContainer = styled.div`
  padding: 1rem 0;
`;

const ScBoxHiddenMobile = styled(ScBox)`
  @media (max-width: 1001px) {
    display: none;
  }
`;

const IndexPage = () => (
  <ScContainer>
    <ScColumnsResponsive>
      <ScBoxHiddenMobile
        style={{
          width: '18rem',
          maxHeight: '25rem',
          marginRight: '2rem',
          justifySelf: 'stretch',
        }}
      >
        <Image
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src="pictures/P1070594_edit.JPG"
        />
      </ScBoxHiddenMobile>
      <ScFlexItem>
        <ScHeader3>Herzlich Willkommen!</ScHeader3>
        <p>
          Die familienfreundliche, ganzjährig geöffnete Ferienwohnung F&M Rath
          liegt mitten im Steirischen{' '}
          <a href="https://www.steiermark.com/de/Thermen-Vulkanland">
            Thermenland
          </a>
          .
        </p>
        In ruhiger Lage ist das Haus ca. 600 m von der{' '}
        <a href="https://www.blumau.com/">
          Hundertwassertherme Bad Blumau entfernt
        </a>
        .
        <p>
          Eine Gästeküche, Grillmöglichkeit im Innenhof und Autoabstellplatz
          stehen zur Verfügung.
        </p>
        Verbringen Sie ruhige erholsame Tage in der geräumigen Ferienwohnung -
        Familie Rath freut sich auf Sie.
        <p>
          Unsere Ferienwohnung hat 2 getrennte Schlafzimmer, eine Küche, Bad und
          WC und ist 84 m² groß. Sie bietet Platz für 2-6 Personen.
        </p>
        <p>
          In der Wohnküche ist ein E-Herd, Kühlschrank Mikrowelle, eine
          Eckbankgruppe läd zum gemütlichen Essen und Verweilen ein. Im
          Wohnzimmer ist eine Couch, welche auch als Liegesofa verwendet werden
          kann. Natürlich ist auch ein Ferseher mit SAT-TV vorhanden. Im
          Badezimmer stehen Dusche als auch eine Badewanne zur Verfügung, ebenso
          eine Waschmaschine. Die Schlafzimmer sind mit Doppelbetten
          ausgestattet und sind ruhig gelegen.
        </p>
        <p>
          Im Innenhof steht eine Sitzgruppe mit Sonnenschirm und Liegestühlen
          für Sie bereit, umrangt von einer Weinlaube. Bettwäsche und Handtücher
          sind vorhanden.
        </p>
        <p>
          Die Wohnung ist ebenerdig und leicht erreichbar, es gibt lediglich
          eine Stufe.
          <br />
          Autoabstellplatz ist vor der Wohnung.
          <br />
          Gratis WLAN ist vorhanden.
          <br />
          Anmeldung bitte bei{' '}
          <a href="https://goo.gl/maps/N2EfGRyvQVjSncDc7">
            Kleinsteinbach 64
          </a>{' '}
          oder unter <a href="tel:+43 664 49 50 184">+43 664 49 50 184</a>.
          <br />
          <br />
          Auf ihren Besuch freuen sich Ferdinand & Maria Rath!
        </p>
      </ScFlexItem>
    </ScColumnsResponsive>
  </ScContainer>
);

export default IndexPage;
