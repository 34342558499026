import { Input, InputNumber } from 'antd';
import {
  IoArrowBack,
  IoCalculator,
  IoInformationCircle,
  IoInformationCircleOutline,
  IoMoon,
  IoPerson,
} from 'react-icons/io5';
import React, { useState } from 'react';
import {
  ScBox,
  ScColumnsResponsive,
  ScFlexAlignCenter,
  ScFlexColCenter,
  ScFlexItem,
  ScHeader3,
  ScHeader4,
  ScImage,
  ScTextBox,
  ScTextSmall,
} from '../components/styled-components';

import Image from '../components/image';
import { ScSeparator } from '../components/styled-components';
import globals from '../utils/variables';
import styled from 'styled-components';
import variables from '../utils/variables';

const ScBoxResponsive = styled(ScBox)`
  max-width: 28rem;

  @media (max-width: 500px) {
    max-width: calc(100% + 2rem);
    width: calc(100% + 2rem);
    margin: -1rem;
    border-radius: 0;
    box-shadow: none;
    border-top: 1px solid #ccc;
    margin-bottom: -1.5rem;
  }
`;

export default () => {
  const [persons, setPersons] = useState(0);
  const [nights, setNights] = useState(0);
  return (
    <div>
      <ScBoxResponsive>
        <ScFlexAlignCenter style={{ alignItems: 'stretch' }}>
          <ScFlexItem>
            <ScFlexAlignCenter
              style={{
                borderBottomRightRadius: '10px',
                borderBottom: '1px solid #ccc',
                borderRight: '1px solid #ccc',
                padding: '0.5rem 1rem ',
                display: 'inline-flex',
                background: variables.secondaryColorTransparent,
                color: '#fff',
              }}
            >
              <IoCalculator size="1.2em" /> &nbsp; Preis berechnen
            </ScFlexAlignCenter>
            <ScFlexColCenter
              style={{ justifyContent: 'end', alignItems: 'start' }}
            >
              <ScFlexAlignCenter
                style={{
                  padding: '0.4rem 0.75rem 0',
                  width: '100%',
                }}
              >
                <ScFlexItem
                  style={{
                    fontSize: '90%',
                  }}
                >
                  Anzahl Personen
                </ScFlexItem>
                <InputNumber
                  placeholder="Personen"
                  // prefix={<IoPerson />}
                  min={1}
                  max={6}
                  style={{ margin: '0 0.5rem' }}
                  onChange={setPersons}
                ></InputNumber>
                <IoPerson />
              </ScFlexAlignCenter>
              <ScFlexAlignCenter
                style={{
                  padding: '0.4rem 0.75rem 0.5rem',
                  width: '100%',
                }}
              >
                <ScFlexItem
                  style={{
                    fontSize: '90%',
                  }}
                >
                  Anzahl Nächte
                </ScFlexItem>
                <InputNumber
                  min={2}
                  placeholder="Nächte"
                  style={{ margin: '0 0.5rem' }}
                  onChange={setNights}
                ></InputNumber>
                <IoMoon />
              </ScFlexAlignCenter>
            </ScFlexColCenter>
          </ScFlexItem>
          <ScFlexColCenter
            style={{
              borderLeft: '1px solid #ccc',
              width: '10rem',
              padding: '0 0.5rem',
              textAlign: 'center',
            }}
          >
            {persons * nights > 0 ? (
              <>
                <div style={{ fontSize: '250%' }}>
                  <span
                    style={{
                      color: variables.mainColor,
                      textAlign: 'center',
                    }}
                  >
                    {((persons >= 3 ? persons - 2 : 0) * 10 + 55) * nights +
                      persons * nights * 3.5 +
                      (nights <= 3 ? 15 : 0)}
                  </span>{' '}
                  €
                </div>
                <div style={{ lineHeight: '1em' }}>
                  <ScTextSmall style={{ fontSize: '80%' }}>
                    Nächtigungsabgabe von <br />
                    <strong> {persons * nights * 3.5}</strong> €{' '}
                    {nights <= 3 && (
                      <span>
                        und Endreinigung von <strong>15</strong> €{' '}
                      </span>
                    )}
                    im Preis inkludiert
                  </ScTextSmall>
                </div>
              </>
            ) : (
              <ScFlexAlignCenter
                style={{ padding: '0.5rem', lineHeight: '1em' }}
              >
                <IoArrowBack
                  size="1.25rem"
                  style={{ marginRight: '0.25rem', minWidth: '1.25rem' }}
                />
                Bitte die <br />
                Anzahl der Personen & Nächte eingeben
              </ScFlexAlignCenter>
            )}
          </ScFlexColCenter>
        </ScFlexAlignCenter>
      </ScBoxResponsive>
    </div>
  );
};
