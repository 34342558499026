import { Input, InputNumber } from 'antd';
import { IoArrowBack, IoCalculator, IoInformationCircle, IoInformationCircleOutline, IoMoon, IoPerson } from 'react-icons/io5';
import React, { useState } from 'react';
import { ScBox, ScColumnsResponsive, ScFlexAlignCenter, ScFlexColCenter, ScFlexItem, ScHeader3, ScHeader4, ScImage, ScTextBox, ScTextSmall } from '../components/styled-components';

import Calculator from '../subpages/calculator';
import Image from '../components/image';
import { ScSeparator } from './../components/styled-components';
import globals from '../utils/variables';
import styled from 'styled-components';
import variables from '../utils/variables';

const ScContainer = styled.div`
  padding: 1.5rem 0;
`;
const ScBoxHiddenMobile = styled(ScBox)`
  @media (max-width: 1001px) {
    display: none;
  }
`;

// let persons = 0;
// let nights = 0;

const IndexPage = () => {
  const [persons, setPersons] = useState(0);
  const [nights, setNights] = useState(0);
  return (
    <ScContainer>
      <ScColumnsResponsive>
        <ScFlexItem>
          <ScHeader3 id="price">Unsere Preise</ScHeader3>
          <ScTextBox style={{ marginTop: '1rem' }}>
            <ScFlexAlignCenter>
              <IoInformationCircleOutline size={'2.8rem'} color={variables.mainColor} style={{ margin: '0 0.5rem' }} />
              <strong>Bitte beachten Sie, dass unsere Ferienwohnung erst ab einem Mindestbuchungs-Zeitraum von 2 Nächten zur Verfügung steht. Wir bitten um Ihr Verständnis.</strong>
            </ScFlexAlignCenter>
          </ScTextBox>
          <div style={{ width: '100%', marginTop: '1.5rem' }}>
            <ScHeader4>Listen-Preise</ScHeader4>
          </div>{' '}
          <ScFlexAlignCenter style={{ width: '100%' }}>
            <div>
              <strong>2 Erwachsene pro Nacht</strong>
              <br />
              <ScTextSmall>Kinder unter 6 Jahre sind gratis</ScTextSmall>
            </div>
            <ScFlexItem style={{ textAlign: 'right', fontSize: '150%', minWidth: '5rem' }}>
              <span style={{ color: variables.mainColor }}>55,--</span> €
            </ScFlexItem>
          </ScFlexAlignCenter>
          <ScSeparator style={{ margin: '0.5rem 0 ' }} />
          <ScFlexAlignCenter style={{ width: '100%' }}>
            <div>
              <strong>jede zus. Person pro Nacht</strong>
              <br />
              <ScTextSmall>(betrifft dritte, vierte und fünfte Person)</ScTextSmall>
            </div>
            <ScFlexItem style={{ textAlign: 'right', fontSize: '150%', minWidth: '5rem' }}>
              <span style={{ color: variables.mainColor }}>+10,--</span> €
            </ScFlexItem>
          </ScFlexAlignCenter>
          <ScSeparator style={{ margin: '0.5rem 0 ' }} />
          <ScFlexAlignCenter style={{ width: '100%' }}>
            <div>
              <strong>Endreinigung bei 2 bzw. 3 Nächtigungen </strong>
              <br />
              <ScTextSmall>(die Endreinigungsgebühr entfällt ab 4 Nächten)</ScTextSmall>
            </div>
            <ScFlexItem style={{ textAlign: 'right', fontSize: '150%', minWidth: '5rem' }}>
              <span style={{ color: variables.mainColor }}>+15,--</span> €
            </ScFlexItem>
          </ScFlexAlignCenter>
          <ScSeparator style={{ margin: '0.5rem 0 ' }} />
          <ScFlexAlignCenter style={{ width: '100%' }}>
            <ScFlexColCenter>
              <strong>Fremdenverkehrsabgabe pro Person und Nacht</strong>
            </ScFlexColCenter>
            <ScFlexItem style={{ textAlign: 'right', fontSize: '150%', minWidth: '5rem' }}>
              <span style={{ color: variables.mainColor }}>+3,50</span> €
            </ScFlexItem>
          </ScFlexAlignCenter>
          <div style={{ margin: '1.85rem 0 ' }} />
          <Calculator />
        </ScFlexItem>
        <ScBoxHiddenMobile
          style={{
            width: '16rem',
            // maxHeight: '25rem',
            marginLeft: '2rem',
            justifySelf: 'stretch',
          }}
        >
          <Image style={{ width: '100%', height: '100%', objectFit: 'cover' }} src="pictures/P1070743_tall.jpg" />
        </ScBoxHiddenMobile>
      </ScColumnsResponsive>
    </ScContainer>
  );
};

export default IndexPage;
