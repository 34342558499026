import {
  ScColumnsResponsive,
  ScFlexAlignCenter,
  ScFlexCenter,
  ScHeader3,
} from './../components/styled-components';

import Image from '../components/image';
import { IoWarningOutline } from 'react-icons/io5';
import React from 'react';
import styled from 'styled-components';

const ScIFrame = styled.div`
  border: 0;
  width: 100%;
  height: 40rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
`;

const IndexPage = ({ cookieAccepted }) => {
  return (
    <div style={{ padding: '1.5rem 0rem' }}>
      <ScColumnsResponsive>
        {/* {cookieAccepted ? ( */}
        <ScIFrame>
          <a
            href="https://goo.gl/maps/K8ZKLvokahJFWV4eA"
            style={{ width: '100%', height: '100%' }}
          >
            <Image
              src="pictures/osm_map.png"
              style={{ width: '100%', height: '100%' }}
            ></Image>
          </a>
          {/* <iframe
              src="https://www.openstreetmap.org/export/embed.html?bbox=16.045296192169193%2C47.118694148077665%2C16.07362031936646%2C47.13073906800933&layer=mapnik&marker=47.124716948949725%2C16.059458255767822"
              allowFullScreen=""
              style={{ width: '100%', height: '40rem', border: '0' }}
              loading="lazy"
            ></iframe> */}
        </ScIFrame>
        {/* ) : (
          <ScFlexAlignCenter>
            <IoWarningOutline size="1.5em" />
            &nbsp; Sie müssen Cookies akzeptieren um die eingebettete Karte von
            Google Maps sehen zu können
            <a href="https://www.openstreetmap.org/?mlat=47.1247&amp;mlon=16.0595#map=16/47.1247/16.0595">
              View Larger Map
            </a>
          </ScFlexAlignCenter>
        )} */}
        <div style={{ marginLeft: '1.5rem' }}></div>
        <div>
          <ScHeader3 id="map">Anfahrt</ScHeader3>
          <ul>
            <li>
              A2 Abfahrt Waltersdorf/Sebersdorf fahren Sie auf der Landesstraße
              L401
            </li>
            <li>
              in Richtung Fürstenfeld durchqueren Sie Bad Walterdorf und
              Leitersdorf
            </li>
            <li>
              nach ungefähr 7km kommen Sie an der Therme Blumau vorbei fahren
              Sie
            </li>
            <li>
              nach 200m in den Kreisverkehr und fahren Sie die dritte Ausfahrt
              ab
            </li>
            <li>
              biegen Sie nach 400m beim Dorfkreuz nach rechts ab biegen Sie nach
            </li>
            <li>
              200m bei der nächsten Querstraße Richtung Untergfangen ab biegen
              Sie
            </li>
            <li>
              auf der rechten Seite nach ungefähr 100m in die dritte Einfahrt ab
              Sie
            </li>
            <li>haben unser Gästehaus erreicht</li>
          </ul>
        </div>
      </ScColumnsResponsive>
    </div>
  );
};

export default IndexPage;
