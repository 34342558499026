import { IoCall, IoMail, IoMap } from 'react-icons/io5';
import {
  ScLogo,
  ScMaxWidthContainer,
  ScMaxWidthContainerWide,
} from './../components/styled-components';

import Image from '../components/image';
import React from 'react';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScIntroContainer = styled.div`
  position: relative;
  height: 625px;
  overflow: hidden;
  margin: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  @media (max-width: 500px) {
    height: 175px;
  }
  @media (min-width: 501px) and (max-width: 1000px) {
    height: 275px;
  }
  @media (min-width: 1001px) and (max-width: 1500px) {
    height: 375px;
  }
  @media (min-width: 1001px) and (max-width: 1500px) {
    height: 475px;
  }
`;

const ScButtonContainer = styled.div`
  position: relative;
  z-index: 100;
  top: 0;
  width: 100%;
  display: flex;
  text-align: center;
  margin-bottom: 1rem;
`;

const ScCTAButton = styled.a`
  padding: 0.35rem;
  padding-left: 1rem;
  color: #333;
  background: transparent;
  display: flex;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  /* border-bottom: 1px solid #ccc; */
  padding-bottom: 1rem;
  align-items: center;
  flex: 1;
  flex-basis: 100px;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 1000px) {
    font-size: 0;
  }

  @media (min-width: 1001px) {
    &:hover {
      background: #ddd;
      background: ${globals.secondaryColor};
      color: #222;
      font-weight: bold;
    }
  }
  @media (max-width: 600px) {
    border: none;
    padding-bottom: 0;
  }

  &:hover .ScIcon {
    box-sizing: border-box;
    /* border: 5rem solid ${globals.mainColor}; */
    background: ${globals.mainColor};
    color: #fff;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`;
const ScIcon = styled.div`
  --ggs: 1.5;
  position: relative;
  border-radius: 100%;
  top: -3rem;
  margin-bottom: -2rem;
  background: #f3f3f3;
  border: 0px solid ${globals.mainColor};
  color: ${globals.mainColor};
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  &:hover {
    /* border: 5rem solid ${globals.mainColor}; */
    background: ${globals.mainColor};
    color: #fff;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  @media (max-width: 420px) {
    height: 4rem;
    width: 4rem;
    top: -2.5rem;
  }
`;

const ScImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0%;
  width: 100%;
  z-index: -1;
  object-fit: cover;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.22), 0 3px 2px rgba(0, 0, 0, 0.34);
`;

const ScSlogan = styled.div`
  font-size: 19pt;
  font-family: 'ArimaMadurai';
  line-height: 110%;
  position: absolute;
  bottom: 1.2rem;
  color: ${globals.mainColor};
  text-shadow: 1px 1px 1px #fff;
  font-weight: bold;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export default () => (
  <div>
    <ScIntroContainer>
      <ScMaxWidthContainer>
        <ScSlogan>
          Ferienwohnung
          <br />
          F&M Rath
        </ScSlogan>
      </ScMaxWidthContainer>
      <ScImage>
        <Image
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src="pictures/main_corrected_2.jpg"
        />
      </ScImage>
    </ScIntroContainer>
    <div>
      <ScMaxWidthContainer>
        <ScButtonContainer>
          <ScCTAButton href="tel:+43 664 49 50 184">
            <ScIcon className="ScIcon">
              <IoCall size="2rem" />
            </ScIcon>
            +43 664 49 50 184
          </ScCTAButton>
          <ScCTAButton href="mailto:maria@gaestehaus-rath.at">
            <ScIcon className="ScIcon">
              <IoMail size="2rem" />
            </ScIcon>
            maria@gaestehaus-rath.at
          </ScCTAButton>
          <ScCTAButton href="https://goo.gl/maps/K8ZKLvokahJFWV4eA">
            <ScIcon className="ScIcon">
              <IoMap size="2rem" />
            </ScIcon>
            Kleinsteinbach 2 <br />
            8283 Bad Blumau
          </ScCTAButton>
        </ScButtonContainer>
      </ScMaxWidthContainer>
    </div>
  </div>
);
