import {
  ScBox,
  ScColumnsResponsive,
  ScFlexItem,
  ScHeader3,
  ScHeader4,
  ScImage,
} from '../components/styled-components';

import Image from '../components/image';
import React from 'react';
import { ScFlexCenter } from './../components/styled-components';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScContainer = styled.div`
  padding: 1.5rem 0;
`;

const IndexPage = () => (
  <ScContainer>
    <ScHeader3 id="furnishing">Ausstattung</ScHeader3>
    <ScColumnsResponsive style={{ margin: '-0.25rem' }}>
      <ScBox style={{ flex: '1', margin: '0.25rem', width: '100%' }}>
        <Image
          style={{
            width: '100%',
            // height: '100%',
            objectFit: 'cover',
            minWidth: '0',
          }}
          src="pictures/P1070660_edit.JPG"
        />
        <ScFlexItem
          style={{ padding: '0.25rem 1rem', height: '100%', width: '100%' }}
        >
          <ScHeader4>Küche</ScHeader4>
          <p>
            In der Wohnküche ist ein Küchenblock mit E Herd + Backrohr,
            Ceranfeld und Dunstabzug, ein Kühlschrank mit Gefrierfach und eine
            Mikrowelle. Für die Zubereitung des Frühstücks gibt es einen
            Toaster, Eierkocher,Wasserkocher, Kaffeefilterautomat und eine
            Kapsel Kaffeemaschine von Eduscho.
            <br />
            Eine gemütliche Eckbank ladet zum Sitzen und Verweilen ein.
          </p>
        </ScFlexItem>
      </ScBox>
      <ScBox style={{ flex: '1', margin: '0.25rem', width: '100%' }}>
        <Image
          style={{
            width: '100%',
            // height: '100%',
            objectFit: 'cover',
            minWidth: '0',
          }}
          src="pictures/P1070696_edit.JPG"
        />
        <ScFlexItem
          style={{ padding: '0.25rem 1rem', height: '100%', width: '100%' }}
        >
          <ScHeader4>Wohnzimmer</ScHeader4>
          <p>
            Im Wohnzimmer gibt es eine Wohnwand, SAT TV und eine Wohnlandschaft,
            welche mit einem Handgriff (bei Bedarf) zu einem Bett umfunktioniert
            werden kann.
          </p>
        </ScFlexItem>
      </ScBox>
      <ScBox style={{ flex: '1', margin: '0.25rem', width: '100%' }}>
        <Image
          style={{
            width: '100%',
            // height: '100%',
            objectFit: 'cover',
            minWidth: '0',
          }}
          src="pictures/P1070515_edit.JPG"
        />
        <ScFlexItem
          style={{ padding: '0.25rem 1rem', height: '100%', width: '100%' }}
        >
          <ScHeader4>Bad</ScHeader4>
          <p>
            Im Bad sind Dusche, Badewanne, Waschtisch, Fön, Handtücher und eine
            Waschmaschine.
          </p>
        </ScFlexItem>
      </ScBox>
    </ScColumnsResponsive>
    <ScColumnsResponsive style={{ margin: '-0.25rem', marginTop: '0.25rem' }}>
      <ScBox style={{ flex: '1', margin: '0.25rem', width: '100%' }}>
        <Image
          style={{
            width: '100%',
            // height: '100%',
            objectFit: 'cover',
            minWidth: '0',
          }}
          src="pictures/P1070566_edit.JPG"
        />
        <ScFlexItem
          style={{ padding: '0.25rem 1rem', height: '100%', width: '100%' }}
        >
          <ScHeader4>Erstes Schlafzimmer</ScHeader4>
          <p>
            Im Schlafzimmer ist ein Doppelbett fußfrei und ein dreiteiliger
            Kasten. Bei Bedarf ist Platz für ein Gitterbett oder ein Zusatzbett.
          </p>
        </ScFlexItem>
      </ScBox>
      <ScBox style={{ flex: '1', margin: '0.25rem', width: '100%' }}>
        <Image
          style={{
            width: '100%',
            // height: '100%',
            objectFit: 'cover',
            minWidth: '0',
          }}
          src="pictures/P1070670.JPG"
        />
        <ScFlexItem
          style={{ padding: '0.25rem 1rem', height: '100%', width: '100%' }}
        >
          <ScHeader4>Zweites Schlafzimmer</ScHeader4>
          <p>
            Im zweiten Schlafzimmer ist ein Doppelbett fußfrei und ein
            dreiteiliger Kasten.
          </p>
        </ScFlexItem>
      </ScBox>
      <ScBox style={{ flex: '1', margin: '0.25rem', width: '100%' }}>
        <Image
          style={{
            width: '100%',
            // height: '100%',
            objectFit: 'cover',
            minWidth: '0',
          }}
          src="pictures/P1070608 (2).JPG"
        />
        <ScFlexItem
          style={{ padding: '0.25rem 1rem', height: '100%', width: '100%' }}
        >
          <ScHeader4>Innenhof</ScHeader4>
          <p>
            Im Innenhof ist eine Sitzecke umgeben von Weinreben. Hier kann man
            frühstücken oder den Abend gemütlich ausklingen lassen.
          </p>
        </ScFlexItem>
      </ScBox>
    </ScColumnsResponsive>
  </ScContainer>
);

export default IndexPage;
